<template>
	<div>
		<div class="recharge_top">
			<p class="recharge_money">余额 <span class="money">{{all_data.balance}}</span>元 </p>
            <p class="recharge_money bond_money">保证金金额 <span class=" bond_money2">{{all_data.credit_limit}}</span>元 </p>
			<a-button type="primary" class='recharge_add' @click='recharge_money'> 充值</a-button>
			<a-button @click='recharge_money_tips' class='recharge_setting'> 充值提醒</a-button>
		</div>
		<EditPop @handleSubmit="submit_recharge" :width='750' :onText="'确认购买并支付'" class='edit_pop'
			:form_data="form_recharge" :visible.sync="visible_data_recharge">
			<template slot='keyword1'>
				<p><span class="label_recharge"><strong class="icon_req">*</strong>当前余额：</span><span
						class="balance">{{all_data.balance}}</span>元</p>
			</template>
			<template slot='keyword2'>
				<div class="label_recharge_box">
					<div class="label_recharge"> <strong class="icon_req">*</strong>充值金额：</div>
					<div :class="index==choose_index?'money_item active_item':'money_item'"
						@click="choose_money(index,money)" v-for="(money,index) in money_list">
						{{money.toLocaleString()}}
						<img class="choose_img" v-if="index==choose_index" src="../../assets/img/gg.png"></img>
					</div>
				</div>
				<p class="other_money">其他金额<a-input v-model="other_price" min='100' type='number'
						@change='change_money' required class='price_input'>
					</a-input>元 </p>
			</template>
			<template slot='keyword3'>
				<p><span class="label_recharge"> <strong class="icon_req"></strong> 支付金额：</span><span
						class="pay_money">{{pay_money.toLocaleString()}}元</span></p>
			</template>
		</EditPop>
		
		<EditPop @handleSubmit="submit_upload"   class='edit_pop'
			:form_data="form_submit_upload" :visible.sync="visible_submit_upload">
		</EditPop>
		
		
		<EditPop @handleSubmit="submit_true"  :width='700' :form_data="form_recharge_true"
			class='edit_pop' :visible.sync="data_recharge_true">
			<template slot='keyword1'>
				<div>
					<div>
						<div class="order_info">
							订单信息
						</div>
						<div class="order_info">
							<p>订单编号：<span>{{order_data.order_id}}</span></p>
							<p>下单时间：<span>{{order_data.create_time}}</span></p>
						</div>
						<div class="order_info">
							<p>企业名称：<span>{{order_data.company_name}}</span></p>
							<p>订单金额：<span>{{ Number(order_data.price).toLocaleString()}}元</span></p>
						</div>
						<div class="order_info">
							<p>收款企业：<span>{{collection.account_name}}</span></p>
							<p><span></span></p>
						</div>
					</div>
					<p class="pop_pay_tips">请在<span class="tips_color">{{collection.end_time}}</span>前完成银行汇款转账支付 <span
							class="tips_color">{{Number(order_data.price).toLocaleString()}}</span>元 </p>

					<div class="remittance_info">
						<p class="remittance_tips">汇款时，请填写以下信息</p>
						<div>
							<p class="remittance_li"><span>收款银行账户名称</span><span>{{collection.account_name}}</span></p>
							<p class="remittance_li remittance_li_no"><span>收款银行账号</span><span>{{collection.account_number}}</span></p>
							<p class="remittance_li"><span>银行名称</span><span>{{collection.bank_name}}</span></p>
							<p class="remittance_li remittance_li_no"><span>开户行</span><span>{{collection.bank_of_deposit}}</span></p>
						</div>
					</div>
					<p class="tips1">汇款提醒：</p>
					<p class="tips1"> 1.转账金额与订单金额务必一致，不得多转或少转；</p>
					<p class="tips1">2.收款账户到账后，订单即支付成功，账户余额才会充值。</p>

				</div>
			</template>
		</EditPop>
		<!-- 重置提醒 -->
		<EditPop @handleSubmit="set_tips" class='edit_pop' :form_data="recharge_tips"
			:visible.sync="visible_recharge_tips">
			<template slot='keyword2'>
				<p class="other_money other_money2"><span>*</span> 提醒预警：<a-input v-model="price" type='number' required
						class='price_input'></a-input>元以下 </p>
			</template>
			<template slot='keyword3'>
				<div class="inputbox inputbox2">
					<div class="other_money other_money3"><span>*</span>提醒电话：</div>
					<div>
						<p v-for="(item,index) in phone_list">
							<a-input v-model="item.phone" type='phone' :valu="item.phone" required class='phone_input'>
							</a-input>
							<a-icon v-if='(index+1)==phone_list.length' class="add_phone" type="plus-circle"
								@click="add_phone" />
							<a-icon v-else type="minus-circle" class="add_phone" @click='del_phone(index)' />
						</p>
					</div>
				</div>
			</template>
		</EditPop>
		<TableList :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo"
			:slot_table_list="['operation','status','price','is_invoice']" :submit_preprocessing="submit_preprocessing" ref="list"
			:rowKey="'id'">
			<template slot="right_btn">
				<a-button @click="setting_invoice()" type="primary">设置发票信息</a-button>
			</template>
			
			<template slot="price" slot-scope="data">
				<span>{{Number(data.record.price).toLocaleString()}}</span>
			</template>
			<template slot="left_btn">
				<div class="left_btn_box">
					<div class="recharge_all">
						<p>累计充值<span class="all_money">{{all_data.add_up}}</span>元</p>
						<p>酒店消耗<span class="all_money">{{all_data.consume}}</span>元</p>
					</div>
				</div>
			</template>
			<template slot="operation" slot-scope="data">
				<span v-if="data.record.status==1">
					
				</span>
				<span v-else-if ="data.record.status==2">
					<a  @click="open_orderBilling(data.record)" v-if="data.record.is_invoice==0">开票</a>
					<a-divider type="vertical" v-if="data.record.is_invoice==0" />
					<a v-if="data.record.is_invoice==1">开票中</a>
					<a  v-if="data.record.is_invoice==2"  :href="data.record.invoice+'?response-content-type=application/octet-stream'"   >下载</a>
					<a-divider type="vertical" v-if="data.record.is_invoice==2" />
						<a @click="see_img(data.record)" v-if="data.record.is_invoice==2">查看</a>
				</span>
				<span v-else>
					<a @click="see_order(data.record)" >查看</a>
					<a-divider type="vertical"  />
					<a @click="see_up(data.record)" >上传</a>
				</span>
			</template>
			<template slot="status" slot-scope="data">
				<span v-if="data.record.status==1">确认中</span>
				<span v-if="data.record.status==0">待确认</span>
				<span v-if="data.record.status==2">已确认</span>
			</template>
			<template slot="is_invoice" slot-scope="data">
				<span v-if="data.record.is_invoice==0">待开票</span>
				<span v-if="data.record.is_invoice==1">开票中</span>
				<span v-if="data.record.is_invoice==2">已开票</span>
			</template>
		</TableList>
		<EditPop @handleSubmit="submit_invoice" ref='form_invoice' class='edit_pop' :width='800'
			:form_data="form_invoice" :visible.sync="visible_data_invoice">
		</EditPop>
		<EditPop @handleSubmit="submit_invoice_img" ref='' class='edit_pop' :width='800' 
			:form_data="form_invoice_img" :visible.sync="visible_data_img" >
			<template slot='img'>
                <div class="img_box_info">
                    <img :src="img" alt="" class="img">
                    <a class='dowm_img'  :href="img+'?response-content-type=application/octet-stream'"   download='发票.jpg' >下载</a>
                </div>
			
			</template>
		</EditPop>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
	import SelectUser from "@/components/SelectUser";
	import {
		message
	} from 'ant-design-vue'
	import {
		getList,
		getCensus,
		setRechargeTips,
		getRechargeConfig,
		createOrder,
		setInvoice,
		rechargeOrderInfo,
		orderBilling,
		uploadVoucher
	} from "@/api/evection";
	import {
		getUserList
	} from "@/api/personnel";

	const columns = [{
			title: "订单编号",
			dataIndex: "order_id"
		},
		{
			title: "充值时间",
			dataIndex: "create_time",
			
		},
		{
			title: "充值人",
			dataIndex: "username",
			
		},
		{
			title: "充值金额",
			dataIndex: "price",
			scopedSlots: {
				customRender: "price"
			}
		},
		{
			title: "订单状态",
			dataIndex: "status",
			scopedSlots: {
				customRender: "status"
			}
		},
		{
			title: "发票状态",
			dataIndex: "is_invoice",
			scopedSlots: {
				customRender: "is_invoice"
			}
		},

		{
			title: "操作",
			dataIndex: "operation",
			scopedSlots: {
				customRender: "operation"
			}
		}
	];
	export default {
		name: "Index",
		components: {
			EditPop,
			Form,
			TableList,
			SelectUser
		},
		data() {
			return {
				img:'',
				visible_data_img:false,
				price:'',
				visible_submit_upload:false,
				form_submit_upload:{
					title: "上传打款凭证",
					show_submit_btn: false,
					show_close_btn: false,
					close_reset_btn: false,
					list:[{
                        type: 'upload',
                        name: 'voucher',
                        title: '上传打款凭证',
                        options: {},
                    },]
				},
				money_list: [50000, 100000, 200000],
				pay_money: 0,
				all_data: {},
				choose_index: 0,
				data_recharge_true: false,
				phone_list: [],
				config: this.$config,
				get_table_list: getList,
				visible_data_invoice: false,
				visible_recharge_tips: false,
				submit_preprocessing: {
					array_to_string: ['department_id', 'group_id']
				},
				columns,
				selectedRowKeys: [],
				loading: false,
				visible_data_recharge: false,
				form_invoice_img:{
					title: "下载图片",
					show_submit_btn: false,
					show_close_btn: false,
					close_reset_btn: false,
					list: [{
							type: "slot",
							name: "img",
							title: "",
							placeholder: "",
							
						}]
				},
				form_invoice: {
					title: "设置发票信息",
					show_submit_btn: false,
					show_close_btn: false,
					close_reset_btn: false,
					list: [{
							type: "text",
							name: "invoice_rise",
							title: "发票抬头",
							placeholder: "请填写发票抬头",
							options: {
								rules: [{
									required: true,
									message: "请填写发票抬头"
								}]
							},
						},
						{
							type: "text",
							name: "credit_code",
							title: "统一社会信用代码/税务登记号",
							placeholder: "请填写统一社会信用代码/税务登记号",
							options: {
								rules: [{
									required: true,
									message: "请填写统一社会信用代码/税务登记号"
								}]
							},
						},
						{
							type: "text",
							name: "bank_name",
							title: "开户行",
							placeholder: " 请填写开户行",
							options: {
								rules: [{
									required: true,
									message: "请填写开户行"
								}]
							},
						},
						{
							type: "text",
							name: "account_number",
							title: "银行账号",
							placeholder: " 请填写银行账号",
							options: {
								rules: [{
									required: true,
									message: "请填写银行账号"
								}]
							},
						},
						{
							type: "text",
							name: "address",
							title: "注册地址",
							placeholder: "请填写注册地址",
							options: {
								rules: [{
									required: true,
									message: "请填写注册地址"
								}]
							},
						},

						{
							type: "text",
							name: "phone",
							title: "公司电话",
							placeholder: "请填写公司电话",
							options: {
								rules: [{
									required: true,
									message: "请填写公司电话"
								}]
							},
						},
						{
							type: "text",
							name: "email",
							title: "接收邮箱",
							placeholder: "请填写发票接收邮箱",
							options: {
								rules: [{
									required: true,
									message: "请填写发票接收邮箱"
								}]
							},
						},
					],
				},
				form_recharge_true: {
					title: "充值确认",
					show_submit_btn: false,
					show_close_btn: false,
					close_reset_btn: false,
					list: [{
							type: "slot",
							name: "keyword1",
							title: "",
							options: {

							}
						}

					]
				},
				recharge_tips: {
					title: "充值提醒",
					show_submit_btn: false,
					show_close_btn: false,
					close_reset_btn: false,
					list: [
						{
							type: "slot",
							name: "keyword2",
							title: "",
							options: {

							}
						},
						{
							type: "slot",
							name: "keyword3",
							title: "",
							options: {

							}
						},
					]
				},
				form_recharge: {
					title: "酒店余额充值",
					show_submit_btn: false,
					show_close_btn: false,
					close_reset_btn: false,
					list: [{
							type: "slot",
							name: "keyword1",
							title: "",
							options: {

							}
						},
						{
							type: "slot",
							name: "keyword2",
							title: "",
							options: {

							}
						},
						{
							type: "slot",
							name: "keyword3",
							title: "",
							options: {

							}
						},
					]
				},
				form_data_seo: {
					list: [

						{
							type: "text",
							name: "keyword",
							title: "充值人",
							placeholder: "充值人",
							options: {}
						},
						// {
						// 	type: "text",
						// 	name: "is_invoice",
						// 	title: "发票状态",
						// 	placeholder: "发票状态",
						// 	options: {}
						// },

						{
							type: "select",
							name: "is_invoice",
							title: "发票状态",
							mode: "default",
							options: {},
							list: [{
									key: "0",
									value: "未开发票"
								},
								{
									key: "1",
									value: "开票中"
								},
								{
									key: "2",
									value: "已开发票"
								},

							]
						},
						{
							type: "range_date",
							name: "range_date1",
							title: "充值时间",
							options: {},
							start: {
								name: 'start_time'
							},
							end: {
								name: 'end_time'
							},
						},

					],
					...this.$config.form_data_seo,

				},
				other_price: 0,
				pay_info: {},
				collection:{},
				order_data:{},
				order_id:''
			};
		},


		async created() {
			this.get_info();

			this.pay_money = this.money_list[0]
		},
		methods: {
			see_up(data){
				this.order_id = data.order_id
				this.visible_submit_upload= true;
			},
			see_order(data){
				this.pay_info.order_id = data.order_id
				this.data_recharge_true= true;
				this.get_order()
			},
			open_orderBilling(data){
				orderBilling({data:{
					info:true,
					order_id:data.order_id
				},info:true}).then(res=>{
                    this.$refs.list.get_list();
				})
			},
			get_order(){
				rechargeOrderInfo({data:{
					order_id:this.pay_info.order_id
				}}).then(res=>{
					this.order_data = res.data.data
					this.collection = res.data.collection
				})
			},
			submit_recharge() {
				// if (this.pay_money < 50000) {
				// 	message.error('充值金额需大于50,000');
				// 	return;
				// }
				createOrder({
					data: {
						price: this.pay_money
					}
				}).then(res => {
					this.pay_info = res.data;
					this.data_recharge_true = true;
					this.visible_data_recharge = false
					this.get_order()
				})
			},
			change_money(data) {
				this.choose_index = -1
				this.pay_money = this.other_price
			},
			choose_money(index, money) {
				this.choose_index = index;
				this.pay_money = money
			},
			set_tips() {
				let list = []
				this.phone_list.forEach(item => {
					if (item.phone) {
						list.push(item.phone)
					}
				})
				if (this.price && list.join(',')) {
					setRechargeTips({
						data: {
							price: this.price,
							phone: list.join(','),
							info: true
						}
					}).then(res => {
						this.visible_recharge_tips = false
					})
				}

			},
			get_info() {
				getCensus().then(res => {
					this.all_data = res.data.data
				})
			},
			setting_invoice() {
				this.visible_data_invoice = true
				this.get_setting_info('invoice')
			},

			del_phone(index) {
				this.phone_list.splice(index, 1)
			},
			add_phone() {
				this.phone_list.push({
					phone: ''
				})
			},
			get_setting_info(type) {
				getRechargeConfig({
					data: {
						type: type
					}
				}).then(res => {
					if (type == 'recharge_tips') {
						this.price = res.data.data.price;
						this.phone_list = []
						let phone_list = res.data.data.phone.split(',')
						phone_list.forEach(item => {
							let obj = {
								phone: item
							}
							this.phone_list.push(obj)
						})
					} else {
						this.invoice_info = res.data.data
						this.$refs.form_invoice.setFieldsValue({
							invoice_rise: res.data.data.invoice_rise
						});
						this.$refs.form_invoice.setFieldsValue({
							credit_code: res.data.data.credit_code
						});
						this.$refs.form_invoice.setFieldsValue({
							account_number: res.data.data.account_number
						});
						this.$refs.form_invoice.setFieldsValue({
							bank_name: res.data.data.bank_name
						});
						this.$refs.form_invoice.setFieldsValue({
							phone: res.data.data.phone
						});
						this.$refs.form_invoice.setFieldsValue({
							address: res.data.data.address
						});
						this.$refs.form_invoice.setFieldsValue({
							email: res.data.data.email
						});
					}
				})
			},
			recharge_money_tips() {
				this.visible_recharge_tips = true;
				this.get_setting_info('recharge_tips')
			},
			recharge_money() {
				this.visible_data_recharge = true
			},
			submit_invoice(data) {
				setInvoice({
					data: data.values,
					info: true,
				}).then(res => {
					this.visible_data_invoice = false;
					this.get_setting_info('invoice')
				})
			},
			submit_true(data) {
					this.data_recharge_true = false;
                     this.$refs.list.get_list();
                    
			},
			submit_upload(data){
				data.values.order_id=this.order_id
				uploadVoucher({
					data:data.values,
					info:true
				}).then(res=>{
					this.$refs.list.get_list();
					this.visible_submit_upload= false;
				})
			},
			submit_invoice_img(){
                this.visible_data_img = false;
				 this.$refs.list.get_list();
			},
			see_img(data){
				this.img= data.invoice;
				this.visible_data_img=true
			}

		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.left_btn_box {
		flex: inherit;

		p {
			margin: 5px 0px;
		}
	}

	.edit_pop {
		.ant-col-19 {
			width: 60%;
		}

		.ant-form-item-label {
			width: 30%;
		}
	}

	.red_color {
		color: red;
	}

	.green_color {
		color: #00CC10;
	}

	.recharge_top {
		height: 80px;
		background: #fff;
		padding: 15px;
		margin: 15px;
		display: flex;
		align-items: center;

		.recharge_money {
			font-size: 16px;
			color: #333;

			.money {
				font-size: 36px;
				color: red;
				display: inline-block;
				margin-left: 10px;
			}

		}

		.recharge_add {
			margin-left: 100px;
		}

		.recharge_setting {
			margin-left: 10px;
		}
	}

	.recharge_all {
		font-size: 16px;
		display: flex;
		color: #333;

		.all_money {
			font-size: 20px;
			color: #FF0000;
			margin-left: 10px;
			margin-right: 5px;
			display: inline-block;
		}

		p:nth-child(2) {
			margin-left: 40px;
		}
	}

	.money_item {
		width: 96px;
		height: 40px;
		opacity: 1;
		background: #ffffff;
		position: relative;
		border-radius: 2px;
		text-align: center;
		line-height: 40px;
		display: inline-block;
		font-size: 16px;
		margin-right: 20px;
		border: 1px solid #d9d9d9;
	}

	.active_item {
		border: 1px solid #1890ff;
	}

	.other_money {
		display: flex;
		align-items: center;
		margin-left: 100px;

		span:nth-child(1) {
			display: inline-block;
			color: red;
			margin-right: 5px;
		}

	}

	.inputbox {
		display: flex;
		.other_money {
			margin-left: 120px;
			align-items: flex-start;
		}
	}
	.inputbox2 {
		.other_money {
			margin-left: 0px;
			align-items: flex-start;
		}
		}
	.price_input {
		width: 100px;
		margin: 10px;
	}

	.phone_input {
		margin: 10px;
		width: 200px;
	}

	.label_recharge {
		width: 90px;
		display: inline-block;
	}

	.balance,
	.pay_money {
		color: #ff8d1a;
		// margin-left: 10px;
		font-size: 18px;
	}

	.pay_money {
		color: #ff8d1a;
	}

	.add_phone {
		color: #00A3FF;
	}

	.order_info {
		width: 540px;
		height: 44px;
		opacity: 1;
		line-height: 44px;
		background-color: #fff;
		border: 1px solid #e9e9e9;
		padding-left: 15px;
		border-radius: 3px 3px 0px 0px;
		font-size: 14px;
		color: #000;
		display: flex;
		border: 1px solid rgba(0, 0, 0, 0.03);

		p {
			flex: 1;
		}
	}

	.order_info:nth-child(1) {
		height: 36px;
		line-height: 36px;
		background-color: rgba(0, 0, 0, 0.03);
	}

	.pop_pay_tips {
		font-size: 16px;
		font-weight: 400;
		text-align: left;
		color: #1f2329;
		margin-top: 16px;
	}

	.tips_color {
		color: #FD8700;
		font-size: 16px;
		display: inline-block;
		margin: 0px 5px;
	}

	.remittance_tips {
		font-size: 16px;
		font-weight: 400;
		text-align: left;
		color: rgba(0, 0, 0, 0.60);
	}

	.remittance_li {
		height: 40px;
		line-height: 40px;
		display: flex;
		width: 500px;
		padding-left: 15px;
		padding-right: 15px;
		background-color: #f9f9f9;
		border: 1px solid #EAEAEA;

		span {
			flex: 1;
			color: rgba(0, 0, 0, 0.60);
		}

		span:nth-child(2) {
			color: #000;
		}
	}

	.remittance_li_no {
		// border-left:1px solid #f9f9f9;
		border: 1px solid #EAEAEA;
		background-color: #fff;
	}

	.tips1 {
		font-size: 14px;
		font-weight: 400;
		text-align: left;
		color: #8c8c8c;
	}

	.icon_req {
		color: red;
		margin-right: 5px;
	}

	.choose_img {
		position: absolute;
		bottom: 0px;
		right: 0px;
	}

	.label_recharge_box {
		display: flex;
	}

	.ant-form-item {
		margin-bottom: 0px;
	}
	.other_money2 {
		margin-left: 40px;
		// margin-top: 20px;
		}
		

		.inputbox2 .other_money3 {
			margin-left: 40px;
			}
            .img_box_info {
                text-align: center;
            }
			.img {
				height: 400px;
			}
			.dowm_img{
				display: block;
				margin: 0 auto;
				margin-top: 30px;
				width: 80px;
				height: 40px;
				font-size: 14px;
				text-align: center;
				line-height: 40px;
				background: #1890ff;
				color: #fff;
				border-radius: 6px;
			}
            .bond_money{
                margin-top: 13px;
                margin-left: 20px;
            }
</style>
